const BISGAARD = [
  [4, 10, 10, 15, 15, 20],
  [5, 10, 10, 10, 20, 35],
  [6, 10, 10, 10, 10, 20],
  [7, 10, 15, 25, 30, 40],
  [8, 10, 20, 50, 65, 75],
  [9, 10, 25, 45, 50, 55],
  [10, 15, 20, 30, 30, 35],
  [11, 10, 10, 30, 55, 65],
  [12, 10, 10, 15, 30, 55],
  [14, 20, 35, 55, 60, 65],
  [15, 20, 35, 70, 80, 90],
  [16, 15, 20, 55, 60, 65],
  [17, 15, 20, 55, 80, 95],
  [18, 20, 20, 20, 15, 20],
  [19, 20, 25, 35, 40, 50],
  [20, 20, 25, 40, 55, 65],
  [21, 20, 30, 65, 70, 75],
  [22, 25, 35, 45, 50, 60],
  [23, 30, 40, 45, 45, 50],
  [24, 30, 45, 45, 35, 30],
  [25, 25, 25, 25, 25, 30],
  [26, 35, 60, 75, 75, 80],
  [27, 25, 25, 45, 65, 80],
  [28, 30, 40, 50, 60, 70],
  [29, 35, 40, 55, 65, 75],
  [30, 35, 45, 65, 65, 75],
  [31, 35, 45, 55, 50, 55],
  [33, 45, 60, 65, 60, 65],
  [34, 30, 25, 30, 40, 50],
  [35, 35, 35, 35, 35, 35],
  [36, 35, 40, 50, 55, 60],
  [37, 35, 45, 65, 80, 95],
  [38, 40, 40, 40, 40, 45],
  [39, 40, 40, 25, 20, 20],
  [40, 45, 50, 55, 55, 60],
  [41, 45, 50, 60, 60, 70],
  [42, 55, 65, 70, 70, 80],
  [43, 45, 40, 40, 50, 65],
  [44, 50, 60, 70, 75, 80],
  [45, 45, 50, 60, 60, 70],
  [46, 55, 60, 55, 50, 55],
  [47, 50, 45, 50, 50, 60],
  [48, 50, 50, 50, 60, 75],
  [49, 55, 55, 65, 65, 75],
  [50, 55, 55, 45, 40, 40],
  [51, 55, 60, 70, 70, 75],
  [52, 60, 65, 80, 90, 100],
  [54, 55, 55, 65, 80, 90],
  [55, 65, 65, 65, 60, 65],
  [56, 70, 75, 80, 75, 80],
  [58, 75, 70, 65, 65, 70]
]

const SE_BISGAARD = [
  [1, 15, 15, 15, 30, 55],
  [2, 15, 15, 20, 60, 60],
  [3, 15, 15, 25, 30, 40],
  [4, 15, 15, 30, 55, 60],
  [5, 15, 20, 30, 30, 35],
  [6, 15, 20, 55, 60, 60],
  [7, 15, 25, 45, 50, 55],
  [8, 20, 20, 20, 55, 20],
  [9, 20, 25, 20, 50, 50],
  [10, 20, 25, 25, 20, 60],
  [11, 20, 25, 35, 40, 50],
  [12, 20, 25, 40, 55, 60],
  [13, 20, 35, 55, 60, 60],
  [14, 25, 25, 20, 55, 60],
  [15, 25, 25, 25, 25, 30],
  [16, 25, 25, 45, 60, 60],
  [17, 25, 30, 20, 60, 60],
  [18, 25, 35, 45, 50, 60],
  [19, 30, 25, 30, 40, 50],
  [20, 30, 30, 20, 30, 55],
  [21, 30, 35, 40, 60, 40],
  [22, 30, 40, 45, 45, 50],
  [23, 30, 45, 45, 35, 30],
  [24, 35, 35, 35, 35, 35],
  [25, 35, 40, 50, 55, 60],
  [26, 35, 40, 55, 60, 60],
  [27, 35, 45, 55, 50, 55],
  [28, 35, 45, 60, 60, 60],
  [29, 40, 40, 25, 20, 20],
  [30, 40, 40, 35, 60, 60],
  [31, 40, 40, 40, 40, 45],
  [32, 45, 40, 40, 50, 60],
  [33, 45, 50, 55, 55, 60],
  [34, 50, 45, 50, 50, 60],
  [35, 50, 50, 50, 60, 60],
  [36, 55, 55, 45, 40, 40],
  [37, 55, 60, 55, 50, 55],
  [38, 60, 60, 60, 60, 60]
]

export { BISGAARD, SE_BISGAARD }
