class EargoGenerationService {
  static GENERATIONS = {
    EARGO_FIVE: "EARGO_FIVE",
    EARGO_SIX: "EARGO_SIX",
    EARGO_SEVEN: "EARGO_SEVEN",
    EARGO_SE: "EARGO_SE",
  }

  // this is just a fallback, and it's overriden in the GenerationsProvider
  static CURRENT_GENERATION = EargoGenerationService.GENERATIONS.EARGO_FIVE

  static GENERATION_DISPLAY_DICT = {
    [EargoGenerationService.GENERATIONS.EARGO_FIVE]: "Eargo 5",
    [EargoGenerationService.GENERATIONS.EARGO_SIX]: "Eargo 6",
    [EargoGenerationService.GENERATIONS.EARGO_SEVEN]: "Eargo 7",
    [EargoGenerationService.GENERATIONS.EARGO_SE]: "Eargo SE",
  }

  static get isEargoFive() {
    return (
      EargoGenerationService.getCurrentGeneration() ===
      EargoGenerationService.GENERATIONS.EARGO_FIVE
    )
  }

  static get isEargoSix() {
    return (
      EargoGenerationService.getCurrentGeneration() ===
      EargoGenerationService.GENERATIONS.EARGO_SIX
    )
  }

  static get isEargoSeven() {
    return (
      EargoGenerationService.getCurrentGeneration() ===
      EargoGenerationService.GENERATIONS.EARGO_SEVEN
    )
  }

  static isEargoSE() {
    return (
      EargoGenerationService.getCurrentGeneration() ===
      EargoGenerationService.GENERATIONS.EARGO_SE
    )
  }

  static getCurrentGeneration() {
    return EargoGenerationService.CURRENT_GENERATION
  }

  static setCurrentGeneration(generation) {
    EargoGenerationService.CURRENT_GENERATION = generation
  }

  static getCurrentGenerationDisplayValue() {
    const generation = EargoGenerationService.getCurrentGeneration()
    return EargoGenerationService.getDisplayValueForGeneration(generation)
  }

  static getDisplayValueForGeneration(generation) {
    return EargoGenerationService.GENERATION_DISPLAY_DICT[generation]
  }

  static hasBassSetting() {
    return !EargoGenerationService.isEargoSE()
  }

  static hasTrebleSetting() {
    return !EargoGenerationService.isEargoSE()
  }

  static hasMaskOffset() {
    return ["EARGO_FIVE", "EARGO_SIX"].includes(
      EargoGenerationService.getCurrentGeneration()
    )
  }
}

export default EargoGenerationService
