import { types } from "mobx-state-tree"

const HearingProfile = types
  .model("HearingProfile", {
    id: types.identifier,
    baseProgramLeft: types.maybeNull(types.string),
    baseProgramRight: types.maybeNull(types.string),
    deviceConfigurationId: types.maybeNull(types.number),
    environmentalOffset: types.maybeNull(types.string),
    isEmpty: types.maybeNull(types.boolean),
    isEnabled: types.maybeNull(types.boolean),
    isHearingAssessmentRes: types.maybeNull(types.boolean),
    position: types.maybeNull(types.number),
    soundTuningLeftVolume: types.maybeNull(types.number),
    soundTuningRightVolume: types.maybeNull(types.number),
    soundTuningLeftTreble: types.maybeNull(types.number),
    soundTuningRightTreble: types.maybeNull(types.number),
    soundTuningLeftBass: types.maybeNull(types.number),
    soundTuningRightBass: types.maybeNull(types.number),
    noiseReduction: types.maybeNull(types.number),
    isLeftFeedbackEnabled: types.maybeNull(types.boolean),
    isRightFeedbackEnabled: types.maybeNull(types.boolean),
    feedbackCorrectionLeft: types.maybeNull(types.array(types.number)),
    feedbackCorrectionRight: types.maybeNull(types.array(types.number)),
    isEcaEnabled: types.maybeNull(types.boolean),
    appliedAt: types.maybeNull(types.number),
  })
  .views((self) => {
    return {}
  })

export default HearingProfile
