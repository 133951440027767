import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import styled from "styled-components"
import colors from "styles/colors"
import { Grid, useScrollTrigger, AppBar, Toolbar } from "@material-ui/core"
import SelectUser from "components/select_user/select_user"
import SelectGeneration from "components/select_generation/select_generation"
import ContextMenu from "./context_menu/context_menu"
import AppConfigService from "../../../services/app_config_service"

const Wrapper = styled.div`
  padding: 0 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  transition: height 0.3s;
`

const StyledAppBar = styled(AppBar)`
  && {
    .MuiToolbar-regular {
      min-height: 42px;
    }

    background-color: ${colors.blue};
    font-weight: bold;
    font-size: 18px;
    line-height: 40px;
  }
`

const StyledToolbar = styled(Toolbar)`
  && {
    padding-left: 0;
    padding-right: 0;
  }
`

const UserSelection = styled.a`
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 44px;
  height: 44px;
  display: inline-block;
  justify-content: center;
`

function ElevationScroll({ children, trigger }) {
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

const DefaultNavbar = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 12,
  })

  const [customerData, setCustomerData] = useState({
    firstName: "",
    lastName: "",
  })

  useEffect(() => {
    const url = new URL(window.location.href)
    const firstName = url.searchParams.get("firstName")
    const lastName = url.searchParams.get("lastName")
    setCustomerData({ firstName, lastName })
  }, [])

  // TODO unmock from sessionStore
  return (
    <ElevationScroll trigger={trigger}>
      <StyledAppBar elevation={10} position="static">
        <StyledToolbar>
          <Wrapper>
            <Grid container>
              <Grid item sm={4}>
                <ContextMenu />
                {AppConfigService.getConfig().DEBUG_MODE ? (
                  <>
                    <SelectUser />
                  </>
                ) : (
                  <UserSelection>
                    {customerData.firstName} {customerData.lastName}
                  </UserSelection>
                )}
              </Grid>
              <Grid item sm={4} align="center">
                <SelectGeneration />
              </Grid>
            </Grid>
          </Wrapper>
        </StyledToolbar>
      </StyledAppBar>
    </ElevationScroll>
  )
}

export default observer(DefaultNavbar)
