import React, { useState } from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { formatDistanceStrict, parseISO } from "date-fns"
import { Collapse, List, ListItem, ListItemText } from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"

const Wrapper = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    height: 1px;
    width: 90%; /* or 100px */
    border-bottom: 1px solid rgba(51, 51, 51, 0.17);
  }
`

const ListTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

const ListValue = styled.div`
  position: absolute;
  right: 16px;
`

const StyledListItemText = styled(ListItemText)`
  && {
    & > span {
      font-size: 14px;
      line-height: 24px;
      font-weight: 800;
    }
  }
`

const StyledListItem = styled(ListItem)`
  && {
    position: relative;
    &:first-child {
      margin-top: 8px;
    }
    margin-bottom: 8px;
    padding-top: 0;
    padding-bottom: 0;
  }
`

const Phone = ({
  device: { phoneModel, operatingSystem, appVersion, updatedAt },
}) => {
  const [expand, setExpand] = useState(true)

  const handleExpand = () => {
    setExpand(!expand)
  }

  return (
    <Wrapper>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem button onClick={handleExpand}>
          <StyledListItemText primary="PHONE" />
          {expand ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledListItem>
              <ListTitle>Phone model</ListTitle>
              <ListValue>{phoneModel}</ListValue>
            </StyledListItem>
            <StyledListItem>
              <ListTitle>Software</ListTitle>
              <ListValue>{operatingSystem}</ListValue>
            </StyledListItem>
            <StyledListItem>
              <ListTitle>App</ListTitle>
              <ListValue>{appVersion}</ListValue>
            </StyledListItem>
            <StyledListItem>
              <ListTitle>Last active</ListTitle>
              <ListValue
                title={formatDistanceStrict(parseISO(updatedAt), new Date(), {
                  addSuffix: true,
                })}
              >
                {new Date(updatedAt).toLocaleDateString()}
              </ListValue>
            </StyledListItem>
          </List>
        </Collapse>
      </List>
    </Wrapper>
  )
}

export default observer(Phone)
