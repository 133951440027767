import { types, getEnv, flow, getRoot } from "mobx-state-tree"
import User from "models/user/user"
import { compact, omit } from "lodash"
import MobileDevice from "models/mobile_device/mobile_device"
import FirmwareVersions from "models/firmware_versions/firmware_versions"
import { compareVersions } from "utils/helpers"
import EargoGenerationService from "../../services/eargo_generation_service"

const INITIAL = "initial"
const LOADING = "loading"
const LOADED = "loaded"
const NOT_FOUND_ERROR = "not_found"
const GET_API_FIRMWARE_VERSIONS = `/v1/php/firmware`
const accountId = new URL(window.location.href).searchParams.get("accountId")
const CUSTOMERS_PATH = `/v1/php/customers/${accountId}`

const SessionStore = types
  .model("SessionStore", {
    state: types.optional(
      types.enumeration([INITIAL, LOADING, LOADED, NOT_FOUND_ERROR]),
      INITIAL
    ),
    users: types.optional(types.map(User), {}),
    userMobileDevices: types.optional(types.map(MobileDevice), {}),
    currentUserId: types.optional(types.string, ""),
    currentEusAddress: types.maybeNull(types.number),
    firmwareVersions: types.maybeNull(types.optional(FirmwareVersions, {})),
  })
  .views((self) => {
    return {
      get isLoaded() {
        return self.state === LOADED
      },
      get isInitial() {
        return self.state === INITIAL
      },
      get isNotFoundError() {
        return self.state === NOT_FOUND_ERROR
      },
      get usersList() {
        return Array.from(self.users.values()).filter(
          (user) =>
            user.generation === EargoGenerationService.getCurrentGeneration() //default for now
        )
      },
      get userId() {
        return self.currentUserId
      },
      get eusAddress() {
        return self.currentEusAddress
      },
      get eargoUserSystems() {
        return self.getUserById(self.userId)?.eargoSystemsList
      },
      get canFetchOtherData() {
        const currentGeneration = EargoGenerationService.getCurrentGeneration()
        return (
          self.userId && self.usersList[0]?.generation === currentGeneration
        )
      },
      get eargoHiArrayList() {
        const HIArray = []
        if (!self.eargoUserSystems) return []
        self.eargoUserSystems.forEach((system) => {
          system.eargoDevicesList.forEach((device) => {
            if (
              device.deviceType === "hi_left" ||
              device.deviceType === "hi_right"
            ) {
              HIArray.push(device.firmwareVersion)
            }
          })
        })
        return compact(HIArray)
      },
      isVersionForMaskEnv(FWVersion) {
        if (self.isLoaded && self.eargoHiArrayList.length === 0) {
          return false
        }
        return !!self.eargoHiArrayList?.filter((HI) => {
          return compareVersions(HI, FWVersion) >= 0
        }).length
      },

      get eargoUserDeactivatedDevices() {
        const deactivatedDevices = []
        if (!self.eargoUserSystems) return []
        const systems = self.eargoUserSystems
        systems.forEach((system) => {
          system.eargoDevicesList.forEach((device) => {
            if (device.status === "DEACTIVATED") {
              deactivatedDevices.push(device)
            }
          })
        })

        return deactivatedDevices
      },
      getUserById(id) {
        return self.users.get(id)
      },
    }
  })
  .actions((self) => {
    const { apiClient } = getEnv(self)

    return {
      startLoading() {
        self.state = LOADING
      },
      endLoading() {
        self.state = LOADED
      },
      setNotFoundError() {
        self.state = NOT_FOUND_ERROR
      },
      setCurrentUserId(id) {
        self.currentUserId = id
      },
      setEusAddress(eusAddress) {
        self.currentEusAddress = eusAddress
      },
      fetchFirmwareVersions: flow(function* fetchFirmwareVersions() {
        yield apiClient.requestManager(
          async () =>
            await apiClient.get(
              `${GET_API_FIRMWARE_VERSIONS}/?generation=${EargoGenerationService.getCurrentGeneration()}`
            ),
          (response) => {
            self.addFirmwareVersions(response.data)
          },
          (e) => {
            getRoot(self).uiStore.openNotification(`${e}`, "error")
            self.setNotFoundError()
          }
        )
      }),
      fetchUsers: flow(function* fetchUsers() {
        yield apiClient.requestManager(
          () =>
            apiClient.get(
              `/v1/php/customers/${accountId}/users?generation=${EargoGenerationService.getCurrentGeneration()}`
            ),
          (response) => {
            response.data.forEach(self.addUser)
          },
          (e) => {
            getRoot(self).uiStore.openNotification(`${e}`, "error")
            self.setNotFoundError()
          }
        )

        yield self.fetchSystems()
      }),
      fetchSystems: flow(function* fetchSystems() {
        yield apiClient.requestManager(
          () =>
            apiClient.get(
              `/v1/php/customers/${accountId}/devices?generation=${EargoGenerationService.getCurrentGeneration()}`
            ),
          (response) => {
            self.addDevices(response.data)
          }
        )
      }),
      fetch: flow(function* fetch() {
        self.startLoading()
        yield self.fetchFirmwareVersions()
        yield self.fetchUsers()

        self.endLoading()
      }),
      updateEUSAddress: flow(function* updateEUSAddress(newAddress) {
        const userId = getRoot(self).sessionStore?.userId
        if (!userId) return

        let body = null
        body = { eus_address: newAddress }
        if (!body) return

        yield apiClient.requestManager(
          () =>
            apiClient.patch(
              `${CUSTOMERS_PATH}/eus_address?generation=${EargoGenerationService.getCurrentGeneration()}`,
              body
            ),
          () => {
            getRoot(self).uiStore.openNotification(
              `New EUS address saved. BLE programming required for activation.`,
              "success"
            )
          },
          (e) => {
            getRoot(self).uiStore.openNotification(
              `${e.errorResponse.data.message}`,
              "error"
            )
            self.setNotFoundError()
          }
        )
        yield self.fetch()
      }),
      addDevices(devices) {
        self.usersList[0].addEargoDevices(devices)
      },
      addUser(attributes) {
        self.users.set(attributes.id, { ...attributes })
        self.setEusAddress(attributes.eusAddress)
        if (!self.currentUserId) {
          self.currentUserId = attributes.id
          apiClient.setUserId(attributes.id)
        }
      },
      addFirmwareVersions(attributes) {
        const isNull = !Object.values(attributes).some(
          (x) => x !== null && x !== ""
        )

        if (!isNull) {
          attributes = omit(attributes, ["ble"])
          Object.assign(self.firmwareVersions, {
            charger: attributes.charger,
            hi: attributes.hi,
          })
        }
      },
      reset() {
        apiClient.setUserId(null)
        self.setCurrentUserId("")
        if (self.eargoSystem?.eargoDevices.size) {
          self.state = INITIAL
          self.eargoSystems.eargoDevices.clear()
        }
      },
    }
  })

export default SessionStore
