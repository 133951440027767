import HearingProfilesService from "../../services/hearing_profiles_service"
import SoundTuningService from "../../services/sound_tuning_service"

import { PRESETS } from "utils/helpers"

const TEMP_FAVOURITES_MOCK_FACTORY = () => {
  const {
    VOLUME,
    BASS,
    TREBLE,
    FEEDBACK_ENABLED_VALUE_NORMAL,
    FEEDBACK_ENABLED_VALUE,
  } = SoundTuningService.defaultDisplayValues
  return {
    favourites: [
      {
        program: "PROGRAM_A",
        soundTuningLeftVolume: VOLUME.DEFAULT,
        soundTuningRightVolume: VOLUME.DEFAULT,
        soundTuningLeftTreble: TREBLE.DEFAULT,
        soundTuningRightTreble: TREBLE.DEFAULT,
        soundTuningLeftBass: BASS.DEFAULT,
        soundTuningRightBass: BASS.DEFAULT,
        isLeftFeedbackEnabled: FEEDBACK_ENABLED_VALUE_NORMAL,
        isRightFeedbackEnabled: FEEDBACK_ENABLED_VALUE_NORMAL,
        isActive: false,
        isHearingAssessmentRes: true,
        appliedAt: parseInt(new Date().getTime() / 1000),
      },
      {
        program: "PROGRAM_A",
        soundTuningLeftVolume: VOLUME.DEFAULT,
        soundTuningRightVolume: VOLUME.DEFAULT,
        soundTuningLeftTreble: TREBLE.DEFAULT,
        soundTuningRightTreble: TREBLE.DEFAULT,
        soundTuningLeftBass: BASS.DEFAULT,
        soundTuningRightBass: BASS.DEFAULT,
        isLeftFeedbackEnabled: FEEDBACK_ENABLED_VALUE,
        isRightFeedbackEnabled: FEEDBACK_ENABLED_VALUE,
        isActive: false,
        isHearingAssessmentRes: false,
        appliedAt: parseInt(new Date().getTime() / 1000),
      },
      {
        program: "PROGRAM_B",
        soundTuningLeftVolume: VOLUME.DEFAULT,
        soundTuningRightVolume: VOLUME.DEFAULT,
        soundTuningLeftTreble: TREBLE.DEFAULT,
        soundTuningRightTreble: TREBLE.DEFAULT,
        soundTuningLeftBass: BASS.DEFAULT,
        soundTuningRightBass: BASS.DEFAULT,
        isLeftFeedbackEnabled: FEEDBACK_ENABLED_VALUE,
        isRightFeedbackEnabled: FEEDBACK_ENABLED_VALUE,
        isActive: false,
        isHearingAssessmentRes: false,
        appliedAt: parseInt(new Date().getTime() / 1000),
      },
      {
        program: "PROGRAM_C",
        soundTuningLeftVolume: VOLUME.DEFAULT,
        soundTuningRightVolume: VOLUME.DEFAULT,
        soundTuningLeftTreble: TREBLE.DEFAULT,
        soundTuningRightTreble: TREBLE.DEFAULT,
        soundTuningLeftBass: BASS.DEFAULT,
        soundTuningRightBass: BASS.DEFAULT,
        isLeftFeedbackEnabled: FEEDBACK_ENABLED_VALUE,
        isRightFeedbackEnabled: FEEDBACK_ENABLED_VALUE,
        isActive: false,
        isHearingAssessmentRes: false,
        appliedAt: parseInt(new Date().getTime() / 1000),
      },
      {
        program: "PROGRAM_D",
        soundTuningLeftVolume: VOLUME.DEFAULT,
        soundTuningRightVolume: VOLUME.DEFAULT,
        soundTuningLeftTreble: TREBLE.DEFAULT,
        soundTuningRightTreble: TREBLE.DEFAULT,
        soundTuningLeftBass: BASS.DEFAULT,
        soundTuningRightBass: BASS.DEFAULT,
        isLeftFeedbackEnabled: FEEDBACK_ENABLED_VALUE,
        isRightFeedbackEnabled: FEEDBACK_ENABLED_VALUE,
        isActive: false,
        isHearingAssessmentRes: false,
        appliedAt: parseInt(new Date().getTime() / 1000),
      },
    ],
  }
}

// Mocked config
const DEFAULT_CONFIG_FACTORY = () => {
  const {
    VOLUME,
    BASS,
    TREBLE,
    FEEDBACK_ENABLED_VALUE,
    ECA_VALUE,
  } = SoundTuningService.defaultDisplayValues

  return {
    changelog: [],
    device_configuration: {
      favourite_left_index: null,
      favourite_right_index: null,
      favourite_left: null,
      favourite_right: null,
      favourite_left_slot: null,
      favourite_right_slot: null,
      is_asymmetrical_hl_checked: false,
      hearing_assessment_left_audiogram_index: 0,
      hearing_assessment_right_audiogram_index: 0,
      favourites_applied_at: null,
      ha_applied_at: null,
      ha_id: null,
      hearing_profiles: [
        {
          id: 1,
          base_program_left: PRESETS.PROGRAM_A,
          base_program_right: PRESETS.PROGRAM_A,
          environmental_offset: "NONE",
          is_hearing_assessment_res: false,
          is_empty: false,
          is_enabled: true,
          sound_tuning_left_volume: VOLUME.DEFAULT,
          sound_tuning_right_volume: VOLUME.DEFAULT,
          sound_tuning_left_treble: TREBLE.DEFAULT,
          sound_tuning_right_treble: TREBLE.DEFAULT,
          sound_tuning_left_bass: BASS.DEFAULT,
          sound_tuning_right_bass: BASS.DEFAULT,
          noise_reduction: HearingProfilesService.getNoiseReductionDefaultValueForOffset(
            PRESETS.PROGRAM_A
          ),
          is_left_feedback_enabled: FEEDBACK_ENABLED_VALUE,
          is_right_feedback_enabled: FEEDBACK_ENABLED_VALUE,
          is_eca_enabled: ECA_VALUE,
          position: 1,
          applied_at: parseInt(new Date().getTime() / 1000),
        },
        {
          id: 2,
          base_program_left: PRESETS.PROGRAM_B,
          base_program_right: PRESETS.PROGRAM_B,
          environmental_offset: "NONE",
          is_hearing_assessment_res: false,
          is_empty: false,
          is_enabled: true,
          sound_tuning_left_volume: VOLUME.DEFAULT,
          sound_tuning_right_volume: VOLUME.DEFAULT,
          sound_tuning_left_treble: TREBLE.DEFAULT,
          sound_tuning_right_treble: TREBLE.DEFAULT,
          sound_tuning_left_bass: BASS.DEFAULT,
          sound_tuning_right_bass: BASS.DEFAULT,
          noise_reduction: HearingProfilesService.getNoiseReductionDefaultValueForOffset(
            PRESETS.PROGRAM_B
          ),
          is_left_feedback_enabled: FEEDBACK_ENABLED_VALUE,
          is_right_feedback_enabled: FEEDBACK_ENABLED_VALUE,
          is_eca_enabled: ECA_VALUE,
          position: 2,
          applied_at: parseInt(new Date().getTime() / 1000),
        },
        {
          id: 3,
          base_program_left: PRESETS.PROGRAM_C,
          base_program_right: PRESETS.PROGRAM_C,
          environmental_offset: "NONE",
          is_hearing_assessment_res: false,
          is_empty: false,
          is_enabled: true,
          sound_tuning_left_volume: VOLUME.DEFAULT,
          sound_tuning_right_volume: VOLUME.DEFAULT,
          sound_tuning_left_treble: TREBLE.DEFAULT,
          sound_tuning_right_treble: TREBLE.DEFAULT,
          sound_tuning_left_bass: BASS.DEFAULT,
          sound_tuning_right_bass: BASS.DEFAULT,
          noise_reduction: HearingProfilesService.getNoiseReductionDefaultValueForOffset(
            PRESETS.PROGRAM_C
          ),
          is_left_feedback_enabled: FEEDBACK_ENABLED_VALUE,
          is_right_feedback_enabled: FEEDBACK_ENABLED_VALUE,
          is_eca_enabled: ECA_VALUE,
          position: 3,
          applied_at: parseInt(new Date().getTime() / 1000),
        },
        {
          id: 4,
          base_program_left: PRESETS.PROGRAM_D,
          base_program_right: PRESETS.PROGRAM_D,
          environmental_offset: "NONE",
          is_hearing_assessment_res: false,
          is_empty: false,
          is_enabled: true,
          sound_tuning_left_volume: VOLUME.DEFAULT,
          sound_tuning_right_volume: VOLUME.DEFAULT,
          sound_tuning_left_treble: TREBLE.DEFAULT,
          sound_tuning_right_treble: TREBLE.DEFAULT,
          sound_tuning_left_bass: BASS.DEFAULT,
          sound_tuning_right_bass: BASS.DEFAULT,
          noise_reduction: HearingProfilesService.getNoiseReductionDefaultValueForOffset(
            PRESETS.PROGRAM_D
          ),
          is_left_feedback_enabled: FEEDBACK_ENABLED_VALUE,
          is_right_feedback_enabled: FEEDBACK_ENABLED_VALUE,
          is_eca_enabled: ECA_VALUE,
          position: 4,
          applied_at: parseInt(new Date().getTime() / 1000),
        },
      ],
      favourites: TEMP_FAVOURITES_MOCK_FACTORY().favourites,
    },
  }
}

export { DEFAULT_CONFIG_FACTORY, TEMP_FAVOURITES_MOCK_FACTORY }
