import React, { useState, useEffect } from "react"
import { observer } from "mobx-react"
import styled from "styled-components"
import { useSessionStore } from "hooks/stores"
import MuiSelect from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Loading from "components/loading/loading"

const StyledMuiSelect = styled(MuiSelect)`
  && {
    min-width: 120px;
    padding: 0 10px;
    background-color: white;
    border-radius: 5px;
  }
`

const FormWrapper = styled.div`
  min-width: 120px;
  display: inline-block;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding-top: 8px;
`

const SelectUser = () => {
  const { isLoaded, usersList, currentUserId } = useSessionStore()

  const handleChange = (event) => {
    const currentDomain = window.location.href.split("/")
    window.location.href = `${
      currentDomain[0] + "//" + currentDomain[2]
    }?accountId=${event.target.value}`
  }

  const [defaultValue, setDefaultValue] = useState("")

  useEffect(() => {
    if (currentUserId) setDefaultValue(currentUserId)
  }, [currentUserId, isLoaded])

  if (!isLoaded)
    return (
      <FormWrapper>
        <Loading thickness={2} size={20} />
      </FormWrapper>
    )

  return (
    <>
      {isLoaded && (
        <FormWrapper>
          <StyledMuiSelect
            disableUnderline
            labelId="notification-label"
            name="notification"
            onChange={handleChange}
            value={defaultValue}
            label="Select User"
          >
            {usersList.map((el, index) => {
              const serials = []
              el.eargoSystemsList.forEach((eargoSystem) => {
                serials.push(
                  eargoSystem?.eargoDevicesList?.filter(
                    (device) => device.deviceType === "charger"
                  )[0]?.serialNumberAscii
                )
              })
              const value = el.eargoSystemsList[0]?.eargoDevicesList.length
                ? `serial_number: ${serials.join(", ")}`
                : `user_id: ${el.id}`
              return (
                <MenuItem key={index} value={el.customer?.sfAccountId}>
                  {value}
                </MenuItem>
              )
            })}
          </StyledMuiSelect>
        </FormWrapper>
      )}
    </>
  )
}

export default observer(SelectUser)
