import React, { useState } from "react"
import styled from "styled-components"
import { Collapse, List, ListItem, ListItemText } from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import EargoDevice from "./eargo_device/eargo_device"

const Wrapper = styled.div`
  && {
    & > nav:last-child {
      padding-bottom: 0;
    }
  }
`

const StyledListItemText = styled(ListItemText)`
  && {
    & > span {
      font-size: 14px;
      line-height: 24px;
      font-weight: 800;
    }
  }
`

const StyledList = styled(List)`
  && {
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      height: 1px;
      width: 90%; /* or 100px */
      border-bottom: 1px solid rgba(51, 51, 51, 0.17);
    }
  }
`

const SystemDeactivated = ({ eargoUserDeactivatedDevices }) => {
  const [expand, setExpand] = useState(true)

  const handleExpand = () => {
    setExpand(!expand)
  }

  return (
    <>
      {!!eargoUserDeactivatedDevices.length && (
        <Wrapper>
          <StyledList component="nav" aria-labelledby="nested-list-subheader">
            <ListItem button onClick={handleExpand}>
              <StyledListItemText>DEACTIVATED DEVICES</StyledListItemText>
              {expand ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {!!eargoUserDeactivatedDevices.length &&
                  eargoUserDeactivatedDevices.map((device, index) => (
                    <EargoDevice key={index} device={device} />
                  ))}
              </List>
            </Collapse>
          </StyledList>
        </Wrapper>
      )}
    </>
  )
}

export default SystemDeactivated
