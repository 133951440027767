import React from "react"
import styled from "styled-components"
import colors from "styles/colors"
import { observer } from "mobx-react"
import {
  useConfigurationStore,
  useHearingEvaluationStore,
  useUiStore,
} from "hooks/stores"
import { Tooltip, LineChart, YAxis, Line, XAxis } from "recharts"

const TooltipWrapper = styled.div`
  padding: 16px;
  border: 1px solid rgba(33, 33, 33, 0.7);
  border-radius: 5px;
  background-color: ${colors.white};
  width: 210px;
`

const TooltipTitle = styled.div`
  font-weight: bold;
`

const LeftEarTooltipLabel = styled.div`
  color: ${colors.numberBlue};
`

const RightEarTooltipLabel = styled.div`
  color: ${colors.purple};
`

const BisgaardIndexTooltipLabel = styled.div`
  color: ${colors.black};
`

const BaseLTooltipLabel = styled.div`
  color: ${colors.numberBlue};
`

const BaseRTooltipLabel = styled.div`
  color: ${colors.purple};
`

const CustomizedDot = ({ cx, cy, value }) => {
  if (value) {
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        width={20}
        height={20}
        viewBox="0 0 44 44"
        fill="#1666B6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 31L13 13"
          stroke="#1666B6"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 31L31 13"
          stroke="#1666B6"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  } else return null
}

const CustomTooltip = ({
  active,
  payload,
  eval: { hearingEval, checkboxRightEar, checkboxLeftEar, checkboxEargo },
}) => {
  if (active && payload && payload.length) {
    const label = payload[0]?.payload.label
    const rightEarPayload = payload.find(
      (payload) => payload.name === "rightThreshold"
    )
    const leftEarPayload = payload.find(
      (payload) => payload.name === "leftThreshold"
    )
    const leftBisgaardIdx = hearingEval?.leftBisgaardIndex
    const rightBisgaardIdx = hearingEval?.rightBisgaardIndex

    return (
      <TooltipWrapper>
        <TooltipTitle>{label}</TooltipTitle>
        <br />
        {checkboxLeftEar && leftEarPayload && (
          <LeftEarTooltipLabel>
            {`Left ear : ${100 - leftEarPayload?.value}`}
          </LeftEarTooltipLabel>
        )}
        {checkboxLeftEar && checkboxEargo && leftEarPayload && (
          <BaseLTooltipLabel>
            {`L Eargo Audiogram : ${100 - leftEarPayload.payload.leftBase}`}
          </BaseLTooltipLabel>
        )}
        <br />
        {checkboxRightEar && rightEarPayload && (
          <RightEarTooltipLabel>
            {`Right ear : ${100 - rightEarPayload?.value}`}
          </RightEarTooltipLabel>
        )}
        {checkboxRightEar && checkboxEargo && rightEarPayload && (
          <BaseRTooltipLabel>
            {`R Eargo Audiogram : ${100 - rightEarPayload.payload.rightBase}`}
          </BaseRTooltipLabel>
        )}

        <br />
        <BisgaardIndexTooltipLabel>
          {`Audiogram Index (L, R) : ${leftBisgaardIdx || "N/A"}, ${
            rightBisgaardIdx || "N/A"
          }`}
        </BisgaardIndexTooltipLabel>
      </TooltipWrapper>
    )
  }
  return null
}

const HearingEvaluationChart = ({
  checkboxLeftEar,
  checkboxRightEar,
  checkboxEargo,
}) => {
  const {
    hearingEvaluationHistoryList,
    getLastApplyIndex,
    previewIndex,
    newHearingAssessment,
    currentBisgaard,
    newHearingAssessmentThresholdsArray,
    calculateBisgaardIndex,
  } = useHearingEvaluationStore()

  const { showHearingAssessmentModal } = useUiStore()
  const { applyForReset } = useConfigurationStore()
  const blankAudiogram = [0, 0, 0, 0, 0, 0]

  const hearingEval = showHearingAssessmentModal
    ? newHearingAssessment
    : hearingEvaluationHistoryList[previewIndex] ||
      hearingEvaluationHistoryList[getLastApplyIndex]

  const bisgaard = currentBisgaard()
  const newObj = { ...newHearingAssessment }

  const leftAudiogram = newHearingAssessment
    ? bisgaard.find((x) => {
        return (
          x[0] ===
          calculateBisgaardIndex(
            newHearingAssessmentThresholdsArray(newObj, "left")
          )
        )
      })
    : bisgaard.find((x) => {
        return x[0] === hearingEval?.leftBisgaardIndex
      }) || blankAudiogram

  const rightAudiogram = newHearingAssessment
    ? bisgaard.find((x) => {
        return (
          x[0] ===
          calculateBisgaardIndex(
            newHearingAssessmentThresholdsArray(newObj, "right")
          )
        )
      })
    : bisgaard.find((x) => {
        return x[0] === hearingEval?.rightBisgaardIndex
      }) || blankAudiogram

  const lIdx = hearingEval?.leftBisgaardIndex
  const rIdx = hearingEval?.rightBisgaardIndex

  const data = [
    {
      label: "500Hz Threshold",
      leftThreshold: 100 - hearingEval?.left500HzThreshold,
      rightThreshold: 100 - hearingEval?.right500HzThreshold,
      leftBase: 100 - (lIdx ? leftAudiogram[1] : 0),
      rightBase: 100 - (rIdx ? rightAudiogram[1] : 0),
      x: 0,
    },
    {
      label: "1000Hz Threshold",
      leftThreshold: 100 - hearingEval?.left1000HzThreshold,
      rightThreshold: 100 - hearingEval?.right1000HzThreshold,
      leftBase: 100 - (lIdx ? leftAudiogram[2] : 0),
      rightBase: 100 - (rIdx ? rightAudiogram[2] : 0),
      x: 33.5,
    },
    {
      label: "2000Hz Threshold",
      leftThreshold: 100 - hearingEval?.left2000HzThreshold,
      rightThreshold: 100 - hearingEval?.right2000HzThreshold,
      leftBase: 100 - (lIdx ? leftAudiogram[3] : 0),
      rightBase: 100 - (rIdx ? rightAudiogram[3] : 0),
      x: 66.8,
    },
    {
      label: "3000Hz Threshold",
      leftThreshold: 100 - hearingEval?.left3000HzThreshold,
      rightThreshold: 100 - hearingEval?.right3000HzThreshold,
      leftBase: 100 - (lIdx ? leftAudiogram[4] : 0),
      rightBase: 100 - (rIdx ? rightAudiogram[4] : 0),
      x: 83.5,
    },
    {
      label: "4000Hz Threshold",
      leftThreshold: 100 - hearingEval?.left4000HzThreshold,
      rightThreshold: 100 - hearingEval?.right4000HzThreshold,
      leftBase: 100 - (lIdx ? leftAudiogram[5] : 0),
      rightBase: 100 - (rIdx ? rightAudiogram[5] : 0),
      x: 100,
    },
  ]

  return (
    <>
      {!applyForReset && hearingEval && (
        <LineChart
          width={202}
          height={333}
          data={data}
          style={{
            zIndex: 1,
            position: "absolute",
            left: "104px",
            top: "14px",
          }}
        >
          <YAxis hide domain={[0, 100]} />
          <XAxis
            hide
            ticks={[0, 33.5, 66.8, 83.5, 100]}
            domain={[0, 100]}
            dataKey="x"
            type="number"
          />
          {checkboxLeftEar && (
            <Line
              isAnimationActive={false}
              type="linear"
              dataKey="leftThreshold"
              stroke="#3C7AB4"
              dot={<CustomizedDot />}
              activeDot={<CustomizedDot />}
            />
          )}
          {checkboxLeftEar && checkboxEargo && (
            <Line
              isAnimationActive={false}
              type="linear"
              dataKey="leftBase"
              stroke="#9CBCD8"
              fill="#9CBCD8"
              strokeDasharray="6 4"
              dot={<CustomizedDot />}
              activeDot={<CustomizedDot />}
            />
          )}
          {checkboxRightEar && (
            <Line
              isAnimationActive={false}
              type="linear"
              dataKey="rightThreshold"
              stroke="#B63138"
            />
          )}
          {checkboxRightEar && checkboxEargo && (
            <Line
              isAnimationActive={false}
              type="linear"
              dataKey="rightBase"
              stroke="#DB989A"
              fill="#DB989A"
              strokeDasharray="6 4"
            />
          )}
          <Tooltip
            content={
              <CustomTooltip
                eval={{
                  hearingEval,
                  checkboxRightEar,
                  checkboxLeftEar,
                  checkboxEargo,
                }}
              />
            }
          />
        </LineChart>
      )}
    </>
  )
}

export default observer(HearingEvaluationChart)
